import lottie from 'lottie-web';
import {gsap} from '../gsap';

export function lottieAnimations() {

    const isReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
    if (isReducedMotion) {
        return;
    }

    const matchMedia = gsap.matchMedia();

    if (document.querySelector('.group-presentation-block')) {
        matchMedia.add('(min-width: 1024px)', () => {
            gsap.timeline({
                scrollTrigger: {
                    trigger: '.group-presentation-block',
                    start: 'top 65%',
                },
            }).from('.feature-list-item', {
                y: 20,
                duration: 0.8,
                opacity: 0,
                stagger: 0.3,
            });
        });
    }

    if (document.querySelector('.splitlines')) {
        matchMedia.add('(min-width: 1024px)', () => {
            gsap.timeline({
                scrollTrigger: {
                    trigger: '.splitlines-block',
                    start: 'center bottom',
                },
            }).from('.splitlines p', {
                x: '-50%',
                duration: 1,
                opacity: 0,
                stagger: 0.3,
            });
        });
    }
}
