'use strict';

export class Accordion {
    constructor(elem) {
        /** @type {Element} */
        this.accordion = elem;
        this.buttons = this.accordion.querySelectorAll('.js-accordion-button');
        for (const button of this.buttons) {
            button.addEventListener('click', this.toggle.bind(this, button));
        }
    }

    toggle(button) {
        const id = button.getAttribute('aria-controls');
        const content = this.accordion.querySelector(`#${id}`);
        const open = button.getAttribute('aria-expanded') === 'true';

        button.setAttribute('aria-expanded', `${!open}`);

        content.toggleAttribute('hidden', open);
    }
}
