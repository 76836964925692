import Splide from '@splidejs/splide';
import {i18n} from './i18n';

export function simpleSlider(element) {
    let splide = new Splide(element, {
        type: 'loop',
        pagination: false,
        fixedWidth: '136px',
        fixedHeight: '50px',
        gap: '1.375rem',
        i18n : i18n,
        breakpoints: {
            768: {
                pagination: false,
                perPage: 2,
                fixedWidth: '90px',
                fixedHeight: '33px',
            },
        },
    });

    splide.mount();
}
