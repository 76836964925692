import {ScrollDirection} from './ScrollDirection';

export class ScrollInteraction {
    constructor() {
        /** @type {HTMLElement} */
        this.header = document.querySelector('#main-header');
        /** @type {HTMLButtonElement} */
        this.scrollTopBtn = document.querySelector('.scroll-top-btn');
        /** @type {HTMLElement[]} */
        this.scrollToElems = document.querySelectorAll('.js-scroll-to');
        this.scrollDirection = new ScrollDirection();
        this.events();
    }

    events() {
        for (let scrollToEl of this.scrollToElems) {
            scrollToEl.addEventListener('click', e => {
                e.preventDefault();
                this.scrollTo(scrollToEl);
            });
        }

        window.addEventListener('scroll', () => this.stickyHeader(), {passive: true});
        this.stickyHeader();
        window.addEventListener('resize', () => this.bodyPadding());
        this.bodyPadding();
    }

    bodyPadding() {
        document.body.style.paddingTop = window.getComputedStyle(this.header).height;
    }

    stickyHeader() {
        this.header.classList.toggle('hidden', this.scrollDirection.detect() === 'down' && window.scrollY > this.header.offsetHeight);
    }

    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    scrollTo(scrollToEl) {
        let href = scrollToEl.getAttribute('href');

        for (let scrollToEl of this.scrollToElems) {
            scrollToEl.classList.remove('active');
        }

        scrollToEl.classList.add('active');

        document.querySelector(href).scrollIntoView({
            block: 'start',
            behavior: 'smooth',
            inline: 'start',
        });
    }
}
