import {onClickOutside} from '@vueuse/core';

export class Dropdown {
    constructor(container, button, menu) {
        this.visible = false;

        this.container = container;
        this.button = button;
        this.menu = menu;
        if (this.button) {
          this.button.addEventListener('click', this.toggle.bind(this));
          onClickOutside(this.container, this.hide.bind(this));
        }
        this.#update();
    }

    toggle() {
        this.visible = !this.visible;
        this.#update();
    }

    show() {
        this.visible = true;
        this.#update();
    }

    hide() {
        this.visible = false;
        this.#update();
    }

    #update() {
      if (this.menu) {
        this.menu.style.display = this.visible ? '' : 'none';
      }
    }
}
