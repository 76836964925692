export class Nav {

    constructor() {
        this.isMenuOpen     = false;
        this.nav            = document.querySelector('#main-header');
        this.button         = this.nav.querySelector('.header-menu-button');
        this.menu           = this.nav.querySelector('.header-menu');
        this.subMenuButtons = Array.from(this.menu.querySelectorAll('.menu-item-button'));

        this.menuToggle();
        this.subMenuToggle();
        this.events();
        this.footerAccordion();
    }

    events() {

        document.addEventListener('keyup', e => {
            if (e.key === "Escape") {
                if (e.target.closest('.menu-item-has-children')) {
                    e.target.closest('.menu-item-has-children').querySelector('button').focus();
                }
            }
        })
    }

    menuToggle() {
      this.button.addEventListener('click', () => {
        this.isMenuOpen = !this.isMenuOpen;
        this.#renderMenuButton();
      });
      this.#renderMenuButton();
    }

    #renderMenuButton() {
      this.button.classList.toggle('open', this.isMenuOpen);
      this.menu.classList.toggle('open', this.isMenuOpen);
    }

    subMenuToggle() {
      this.subMenuButtons.forEach(button => button.addEventListener('click', () => {
        const subMenu = button.nextElementSibling;
        button.classList.toggle('open');
        subMenu.classList.toggle('open');
      }));

      const subMenu = this.menu.querySelector('.sub-menu:has(.current-menu-item)');
      if (subMenu && window.innerWidth < 1024) {
        subMenu.classList.add('open');
      }
    }

    footerAccordion() {
    let footerTitles = document.querySelectorAll('.nav-controler');
    if (window.screen.width < 1024) {
      if (footerTitles) {
        footerTitles.forEach(function(title, content) {
          title.addEventListener('click', function(e) {
            e.preventDefault();
            this.parentNode.classList.toggle('appear');
            footerTitles.forEach(function(title2, content2) {
              if ( content !== content2 ) {
                title2.parentNode.classList.remove('appear');
              }
            });
          });
        });
      }
    }
  }
}
