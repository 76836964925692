export class ScrollDirection {
    #lastPosition = window.scrollY;

    /**
     * @return {'up'|'down'|null}
     */
    detect() {
        const scrollPosition = window.scrollY;
        let direction = null;
        if (scrollPosition > this.#lastPosition) {
            direction = 'down';
        } else if (scrollPosition < this.#lastPosition) {
            direction = 'up';
        }

        this.#lastPosition = scrollPosition;

        return direction;
    }
}
