import { focusableElems } from './helpers';

"use strict";

export class Modal {
    constructor(elem) {
        this.modal = elem;
        this.modalId = elem.id;
        this.onlyMobile = this.modal.classList.contains('js-modal-mobile');
        this.modalSection = this.modal.querySelector(".js-modal-section");
        this.modalOpenBtn = document.querySelectorAll(
            "[data-modal-id=" + this.modalId + "]"
        );
        this.modalCloseBtn = this.modal.querySelectorAll(".js-modal-close");
        this.htmlEl = document.documentElement;
        this.mediaQuery = window.matchMedia('(max-width: 768px)');
        this.bindOpen = this.open.bind(this);
        this.bindClose = this.close.bind(this);

        this.events();
    }

    events = () => {

        if(this.onlyMobile){

            if(this.mediaQuery.matches) {
                this.modal.classList.add('js-modal')
            } else{
                this.modal.classList.remove('js-modal')
            }

            this.mediaQuery.addEventListener('change', (e) => {

                if (e.matches) {
                    this.modal.classList.add('js-modal')
                } else {
                    this.modal.classList.remove('js-modal');
                    this.bindClose()

                }
            });

        }

        trapFocus(this.modal)


        if (this.modalOpenBtn.length > 0) {
            for (let modalOpenBtn of this.modalOpenBtn) {
                modalOpenBtn.addEventListener("click", this.bindOpen);
            }
        }

        if (this.modalCloseBtn.length > 0) {
            for (let modalCloseBtn of this.modalCloseBtn) {

                modalCloseBtn.addEventListener("click", this.bindClose);
            }
        }

        this.modal.addEventListener("click", this.bindClose);

        this.modalSection.addEventListener("click", (e) => e.stopPropagation());
    };

    destroy() {

        if (this.modalOpenBtn.length > 0) {
            for (let modalOpenBtn of this.modalOpenBtn) {
                modalOpenBtn.removeEventListener("click", this.bindOpen);
            }
        }

        if (this.modalCloseBtn.length > 0) {
            for (let modalCloseBtn of this.modalCloseBtn) {
                modalCloseBtn.removeEventListener("click", this.bindClose);
            }
        }

        this.modal.removeEventListener("click", this.bindClose);

        this.modalSection.removeEventListener("click", (e) => {
            e.stopPropagation();
        });
    }

    open() {
        this.htmlEl.style.overflow = "hidden";
        this.modal.classList.add("active");
    }

    close() {
        this.htmlEl.style.overflow = "auto";
        this.modal.classList.remove("active");
    }
}

